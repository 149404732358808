import { useDispatch, useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import {
  selectBillingSubscriptionData,
  selectCustomerSubscriptionData,
  selectElectricityMeterSubscriptionData,
  selectEnergyCharacteristicsSubscriptionData,
  selectGasMeterSubscriptionData,
  selectMainAddressSubscriptionData,
  selectTelecomContractSubscriptionData,
} from '../SubscriptionFormSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import {
  selectBankingModuleConfigurationData,
  selectCommonFormConfigurationData,
  selectCustomerModuleConfigurationData,
} from '../SubscriptionFormConfigurationSelectors'
import { updateAdditionalSalesData, updateCustomerData } from '../SubscriptionFormSlice'
import {
  addCommonFormErrors,
  deleteCommonFormErrors,
} from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSlice'
import { subscriptionFormActions } from '@/containers/SubscriptionFormPage/SubscriptionFormSagas'
import useBusinessType from '@/shared/hooks/useBusinessType'

const useAdditionalSaleFormData = () => {
  const dispatch = useDispatch()
  const {
    zohoId,
    name,
    surname,
    civility,
    phone1,
    phone2,
    email,
    pro_social_reason: proSocialReason,
    pro_siret: proSiret,
  } = useSelector(selectCustomerSubscriptionData)

  const { iban, bic } = useSelector(selectBillingSubscriptionData)
  const { isMcpAgent } = useSelector(selectAuthData)
  const { streetNumber, city, zipcode, address, inseeCode, housingType, tenantType } = useSelector(
    selectMainAddressSubscriptionData,
  )
  const { pceNo: pce } = useSelector(selectGasMeterSubscriptionData)
  const { pdlNo: pdl } = useSelector(selectElectricityMeterSubscriptionData)
  const { siretIsLoading } = useSelector(selectCustomerModuleConfigurationData)
  const { prestationType } = useSelector(selectEnergyCharacteristicsSubscriptionData)

  const { providerId } = useSelector(selectTelecomContractSubscriptionData)

  const { type: businessType } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })

  const removeErrors = error => {
    dispatch(deleteCommonFormErrors(error))
  }

  const customerUpdate = payload => {
    dispatch(updateCustomerData(payload))
  }

  const handleSiretResponse = response => {
    if (response.etablissement?.uniteLegale) {
      customerUpdate({
        pro_ape_code: response.etablissement.uniteLegale.activitePrincipaleUniteLegale ?? '',
        pro_social_reason: response.etablissement.uniteLegale.denominationUniteLegale ?? '',
      })
      return
    }

    if (response.uniteLegale?.periodesUniteLegale) {
      const lastUnit = response.uniteLegale?.periodesUniteLegale[0] ?? {}
      if (lastUnit.denominationUniteLegale) {
        customerUpdate({
          pro_ape_code: lastUnit.activitePrincipaleUniteLegale ?? '',
          pro_social_reason: lastUnit.denominationUniteLegale ?? '',
        })
        return
      }
    }
    dispatch(addCommonFormErrors({ siret: [t('subscriptionForm:customer_module.proSiret.error')] }))
    customerUpdate({
      pro_ape_code: '',
      pro_social_reason: '',
    })
  }
  const verifySiret = () => {
    dispatch({
      type: subscriptionFormActions.CUSTOMER_MODULE_VERIFY_SIRET,
      siret: proSiret,
      callback: handleSiretResponse,
    })
  }

  const {
    verify_iban_url: verifyIbanUrl,
    current_subscriber: subscription,
    assistance: assistancesList,
    axa_email_url: axaEmailUrl,
    additional_sales_url: saveLeadUrl,
    interactions_url: interactionsUrl,
    assuranceProTelco: assuranceProTelcoList,
    assurancePro: assuranceProList,
    connectedDevices: connectedDevicesList,
    verisureTelecom: verisureTelecomList,
    telecomSat: telecomSatList,
    telecomFree: telecomFreeList,
    telecomEnergy: telecomEnergyList,
    telecomComparator: telecomComparatorList,
    surveillanceCamera: surveillanceCameraList,
    protected: protectedList,
    accessoriesSfr: accessoriesSfrList,
    carbonOffset: carbonOffsetList,
    axaAssurance: axaAssuranceList,
    assurance: assuranceList,
    offshoreEnergie: offshoreEnergieList,
    offshoreTelecom: offshoreTelecomList,
    partnership: partnershipList,
    energyPro: energyProList,
    energyProPlaceDesEnergies: energyProPlaceDesEnergiesList,
    bank: bankList,
    bankSG: bankSGList,
    comparateur: comparateurList,
    bankinAssuranceProducts: bankinAssuranceList,
    telecom: telecomList,
    moving: transferMuterLogerList,
    selectraService: selectraServiceList,
    boilers: boilersList,
    verisure: verisureList,
    proxiservePAC: proxiservePACList,
    isolation: isolationList,
    ekwateurSolarKit: ekwateurSolarKitList,
    groupPurchase: groupPurchaseList,
    assistancePro: assistanceProList,
    gasBottle: gasBottleList,
    floraAssurance: floraAssuranceList,
    yagoAssurance: yagoAssuranceList,
    santevetAssurance: santevetAssuranceList,
    eliteSaleProduct: eliteList,
    engieBrusselsProduct: engieBrusselsList,
    verisureTelecomBe: verisureTelecomBeList,
    boilerCoverEneco: boilerCoverEnecoList,
    boilerCoverHomeserve: boilerCoverHomeserveList,
    administrativeService: mesAllocsList,
    tucoenergie: tucoenergieList,
    youStock: youStockList,
    voltalisBizdev: voltalisBizdevList,
    cresus: cresusList,
    energyRenovation: energyRenovationList,
    solarPanelsOtovoTE: solarPanelsProductsList,
    chargingPoint: chargingPointProductsList,
    activationMySelectraProducts: activationMySelectraList,
    axaSmartphoneProducts: axaSmartphoneList,
    izi_eligibility_url: iziEligibilityUrl,
    izi_eligibility_cache_search_url: iziEligibilityCacheSearchUrl,
    additionalSalesStatuses,
    country,
    interaction_edf_mail_url: interactionEdfMailUrl,
    garanka_encrypt: garankaEncryptUrl,
    get_cache_url: additionalSaleCheckCacheUrl,
  } = useSelector(selectPageConfigurationData)

  const { ibanValidationData } = useSelector(selectBankingModuleConfigurationData)

  const isAlreadyMonConseillerPerso =
    !!subscription?.sale_selectra_service && subscription.sale_selectra_service.status_code !== 'abandon'
  const { token, isEditable, mandatoryFields, errors } = useSelector(selectCommonFormConfigurationData)
  const agentEmail = subscription?.created_by?.email ?? ''

  const updateLead = (data, name) => {
    dispatch(updateAdditionalSalesData({ data, name }))
  }

  return {
    zohoId,
    name,
    surname,
    civility,
    phone1,
    phone2,
    email,
    proSocialReason,
    proSiret,
    pdl,
    pce,
    prestationType,
    iban,
    bic,
    streetNumber,
    city,
    zipcode,
    address,
    inseeCode,
    agentEmail,
    housingType,
    tenantType,
    providerId,
    country,
    token,
    isEditable,
    ibanValidationData,
    businessType,
    mandatoryFields,
    errors,
    removeErrors,
    additionalSalesStatuses,
    siretIsLoading,
    verifySiret,
    updateLead,
    urls: {
      verifyIban: verifyIbanUrl,
      saveLeadUrl,
      interactionsUrl,
      iziEligibilityUrl,
      iziEligibilityCacheSearchUrl,
      interactionEdfMailUrl,
      garankaEncryptUrl,
      additionalSaleCheckCacheUrl,
    },
    showOnlyAllowLeadMcp: !isMcpAgent && isAlreadyMonConseillerPerso,
    haveAlreadyAnyLead:
      !!subscription?.sale_telecom_energy ||
      !!subscription?.sale_telecom ||
      !!subscription?.sale_verisure_telecom ||
      !!subscription?.sale_assurance_default ||
      !!subscription?.sale_assurance_emprunteur,
    sales: {
      assistances: {
        list: assistancesList ?? [],
        saleDatabaseData: subscription?.sale_assistance_option_id,
        axaEmailUrl,
      },
      assuranceProTelco: {
        list: assuranceProTelcoList ?? [],
        saleDatabaseData: subscription?.sale_assurance_pro_telco,
      },
      connectedDevices: {
        list: connectedDevicesList ?? [],
        saleDatabaseData: subscription?.sale_connected_devices,
      },
      verisureTelecom: {
        list: verisureTelecomList ?? [],
        saleDatabaseData: subscription?.sale_verisure_telecom,
      },
      telecomSat: {
        list: telecomSatList ?? [],
        saleDatabaseData: subscription?.sale_telecom_sat,
      },
      telecomFree: {
        list: telecomFreeList ?? [],
        saleDatabaseData: subscription?.sale_telecom_free,
      },
      telecomEnergy: {
        list: telecomEnergyList ?? [],
        saleDatabaseData: subscription?.sale_telecom_energy,
      },
      telecomComparator: {
        list: telecomComparatorList ?? [],
        saleDatabaseData: subscription?.sale_telecom_comparator,
      },
      surveillanceCamera: {
        list: surveillanceCameraList ?? [],
        saleDatabaseData: subscription?.sale_surveillance_camera,
      },
      protected: {
        list: protectedList ?? [],
        saleDatabaseData: subscription?.sale_protected,
      },
      carbonOffset: {
        list: carbonOffsetList ?? [],
        saleDatabaseData: subscription?.sale_carbon_offset,
      },
      axaAssurance: {
        list: axaAssuranceList ?? [],
        saleDatabaseData: subscription?.sale_axa_assurance,
      },
      assurance: {
        list: assuranceList ?? [],
        saleDatabaseData: subscription?.sale_assurance_default,
      },
      offshoreEnergie: {
        list: offshoreEnergieList ?? [],
        saleDatabaseData: subscription?.sale_offshore_energie,
      },
      offshoreTelecom: {
        list: offshoreTelecomList ?? [],
        saleDatabaseData: subscription?.sale_offshore_telecom,
      },
      partnership: {
        list: partnershipList ?? [],
        saleDatabaseData: subscription?.sale_partnership,
      },
      leadEnergyPro: {
        list: energyProList ?? [],
        saleDatabaseData: subscription?.sale_energy_pro,
      },
      leadEnergyProPlaceDesEnergies: {
        list: energyProPlaceDesEnergiesList ?? [],
        saleDatabaseData: subscription?.sale_energy_pro_place_des_energies,
      },
      leadBank: {
        list: bankList ?? [],
        saleDatabaseData: subscription?.sale_bank,
      },
      leadTelecom: {
        list: telecomList ?? [],
        saleDatabaseData: subscription?.sale_telecom,
      },
      leadTransferMuterLoger: {
        list: transferMuterLogerList ?? [],
        saleDatabaseData: subscription?.sale_moving_transfer_muter_loger,
      },
      leadMonConseillerPerso: {
        list: selectraServiceList ?? [],
        saleDatabaseData: subscription?.sale_selectra_service,
      },
      myChauffage: {
        list: boilersList ?? [],
        saleDatabaseData: subscription?.sale_boiler_my_chauffage,
      },
      leadVerisure: {
        list: verisureList ?? [],
        saleDatabaseData: subscription?.sale_verisure,
      },
      leadProxiservePAC: {
        list: proxiservePACList ?? [],
        saleDatabaseData: subscription?.sale_proxiserve_p_a_c,
      },
      leadIsolation: {
        list: isolationList ?? [],
        saleDatabaseData: subscription?.sale_isolation,
      },
      leadEkwateurSolarKit: {
        list: ekwateurSolarKitList ?? [],
        saleDatabaseData: subscription?.sale_ekwateur_solar_kit,
      },
      leadGroupPurchase: {
        list: groupPurchaseList ?? [],
        saleDatabaseData: subscription?.sale_group_purchase,
      },
      leadAssistancePro: {
        list: assistanceProList ?? [],
        saleDatabaseData: subscription?.sale_assistance_pro,
      },
      leadGasBottle: {
        list: gasBottleList ?? [],
        saleDatabaseData: subscription?.sale_gas_bottle,
      },
      leadFloraAssurance: {
        list: floraAssuranceList ?? [],
        saleDatabaseData: subscription?.sale_flora_assurance,
      },
      leadYagoAssurance: {
        list: yagoAssuranceList ?? [],
        saleDatabaseData: subscription?.sale_yago_assurance,
      },
      leadSantevetAssurance: {
        list: santevetAssuranceList ?? [],
        saleDatabaseData: subscription?.sale_sante_vet_assurance,
      },
      leadElite: {
        list: eliteList ?? [],
        saleDatabaseData: subscription?.sale_elite,
      },
      leadEngieBrussels: {
        list: engieBrusselsList ?? [],
        saleDatabaseData: subscription?.sale_engie_brussels,
      },
      leadVerisureTelecomBe: {
        list: verisureTelecomBeList ?? [],
        saleDatabaseData: subscription?.sale_verisure_be,
      },
      leadBoilerEneco: {
        list: boilerCoverEnecoList ?? [],
        saleDatabaseData: subscription?.sale_boiler_eneco,
      },
      leadBoilerHomeserve: {
        list: boilerCoverHomeserveList ?? [],
        saleDatabaseData: subscription?.sale_boiler_homeserve,
      },
      mesAllocs: {
        list: mesAllocsList ?? [],
        saleDatabaseData: subscription?.sale_administrative_service,
      },
      leadTucoenergie: {
        list: tucoenergieList ?? [],
        saleDatabaseData: subscription?.sale_tucoenergie,
      },
      leadAssuranceEmprunteur: {
        list: assuranceList ?? [],
        saleDatabaseData: subscription?.sale_assurance_emprunteur,
      },
      leadProxiserve: {
        list: boilersList ?? [],
        saleDatabaseData: subscription?.sale_boiler_proxiserve,
      },
      proxiserveRadiator: {
        list: energyRenovationList ?? [],
        saleDatabaseData: subscription?.sale_energy_renovation_radiator,
      },
      proxiserveWaterHeater: {
        list: energyRenovationList ?? [],
        saleDatabaseData: subscription?.sale_energy_renovation_water_heater,
      },
      leadBankSG: {
        list: bankSGList ?? [],
        saleDatabaseData: subscription?.sale_bank_s_g,
      },
      leadSolarPanelsOtovoTE: {
        list: solarPanelsProductsList ?? [],
        saleDatabaseData: subscription?.sale_solar_panels_otovo_t_e,
      },
      leadComparateur: {
        list: comparateurList ?? [],
        saleDatabaseData: subscription?.sale_comparateur,
      },
      leadBankinAssurance: {
        list: bankinAssuranceList ?? [],
        saleDatabaseData: subscription?.sale_bankin_assurance,
      },
      nextories: {
        list: transferMuterLogerList ?? [],
        saleDatabaseData: subscription?.sale_moving_nextories,
      },
      leadYouStock: {
        list: youStockList ?? [],
        saleDatabaseData: subscription?.sale_you_stock,
      },
      muterLoger: {
        list: transferMuterLogerList ?? [],
        saleDatabaseData: subscription?.sale_moving_muter_loger,
      },
      leadEnergyRenovation: {
        list: energyRenovationList ?? [],
        saleDatabaseData: subscription?.sale_energy_renovation_default,
      },
      leadChargingPoint: {
        list: chargingPointProductsList ?? [],
        saleDatabaseData: subscription?.sale_charging_point,
      },
      leadAssurancePro: {
        list: assuranceProList ?? [],
        saleDatabaseData: subscription?.sale_assurance_pro,
      },
      engieHomeService: {
        list: boilersList ?? [],
        saleDatabaseData: subscription?.sale_boiler_engie_home_service,
      },
      garanka: {
        list: boilersList ?? [],
        saleDatabaseData: subscription?.sale_boiler_garanka,
      },
      accessoriesSfr: {
        list: accessoriesSfrList ?? [],
        saleDatabaseData: subscription?.sale_accessories_sfr,
      },
      leadVoltalisBizdev: {
        list: voltalisBizdevList ?? [],
        saleDatabaseData: subscription?.sale_voltalis_bizdev,
      },
      leadCresus: {
        list: cresusList ?? [],
        saleDatabaseData: subscription?.sale_cresus,
      },
      leadActivationMySelectra: {
        list: activationMySelectraList ?? [],
        saleDatabaseData: subscription?.sale_activation_my_selectra,
      },
      leadAxaSmartphone: {
        list: axaSmartphoneList ?? [],
        saleDatabaseData: subscription?.sale_axa_smartphone,
      },
    },
  }
}

export default useAdditionalSaleFormData
