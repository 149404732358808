import { call, put, select, takeLatest, cancelled } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateTableIsLoading, updateTableData } from '@/containers/DashboardPage/DashboardSlice'
import { updateCurrentSubscriptionDetails } from './DashboardSlice'

export const dashboardActions = {
  LOAD_SUBSCRIPTIONS: 'LOAD_SUBSCRIPTIONS',
  LOAD_SUBSCRIPTION_DETAILS: 'LOAD_SUBSCRIPTION_DETAILS',
  RETRACT_SUBSCRIPTION: 'RETRACT_SUBSCRIPTION',
  ADD_CALL_SUBSCRIPTION: 'ADD_CALL_SUBSCRIPTION',
  ADD_COMMENT_SUBSCRIPTION: 'ADD_COMMENT_SUBSCRIPTION',
}

export function* loadSubscriptions({ url }) {
  const controller = new AbortController()

  try {
    yield put(updateTableIsLoading(true))
    const { authTokenSession, lang } = yield select(selectAuthData)
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })

    const signal = controller.signal
    const response = yield call(fetch, url + '&lang=' + lang, { ...config, signal })
    const data = yield call([response, response.json])

    if (response.status === 200) {
      yield put(updateTableData(data))
      yield put(updateTableIsLoading(false))
    } else {
      console.error('loading error ', data)
    }
  } catch (error) {
    console.error('error in loadSubscriptions', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in loadSubscriptions aborted')
    }
  }
}

export function* loadSubscriptionDetails({ url }) {
  try {
    yield put(updateCurrentSubscriptionDetails(null))
    const { authTokenSession, lang } = yield select(selectAuthData)
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const data = yield call([response, response.json])

    if (response.status === 200) {
      yield put(updateCurrentSubscriptionDetails(data))
    } else {
      console.error('loading details error', data)
    }
  } catch (error) {
    console.error(error)
  }
}

export function* retractSubscription({ url, reason, id, responseCallback }) {
  try {
    const postData = {
      comment: reason,
      subscriber_id: id.toString(),
    }
    const { authTokenSession, lang } = yield select(selectAuthData)
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: postData })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const data = yield call([response, response.json])

    yield call(responseCallback, data.success ?? false)
  } catch (error) {
    console.error(error)
  }
}

export function* addCallToSubscription({ url, id, callback }) {
  try {
    const customUrl = url.replace('*', id)
    const { authTokenSession, lang } = yield select(selectAuthData)
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, customUrl + '?lang=' + lang, config)
    const data = yield call([response, response.json])

    yield call(callback, data.callRegistered ?? false)
  } catch (error) {
    console.error(error)
  }
}

export function* addCommentToSubscription({ url, id, comment, callback }) {
  try {
    const postData = {
      comment,
      subscriber_id: id.toString(),
    }
    const { authTokenSession, lang } = yield select(selectAuthData)
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: postData })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const data = yield call([response, response.json])

    yield call(callback, data.success ?? false)
  } catch (error) {
    console.error(error)
  }
}

export default function* dashboardSagas() {
  yield takeLatest(dashboardActions.LOAD_SUBSCRIPTIONS, loadSubscriptions)
  yield takeLatest(dashboardActions.LOAD_SUBSCRIPTION_DETAILS, loadSubscriptionDetails)
  yield takeLatest(dashboardActions.RETRACT_SUBSCRIPTION, retractSubscription)
  yield takeLatest(dashboardActions.ADD_CALL_SUBSCRIPTION, addCallToSubscription)
  yield takeLatest(dashboardActions.ADD_COMMENT_SUBSCRIPTION, addCommentToSubscription)
}
