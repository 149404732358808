import { Suspense } from 'react'

import FieldSkeleton from '@/containers/SubscriptionFormPage/SubscriptionForm/FieldSkeleton'
import useOfferForm from '@/containers/OrganizationsManagement/OfferForm/useOfferForm'

const OfferAdd = () => {

  const { LocalizedModule } = useOfferForm()
  if (!LocalizedModule) {
    return
  }
  return (
    <Suspense fallback={<FieldSkeleton />}>
      <LocalizedModule />
    </Suspense>
  )

}

export default OfferAdd
