export const OrganizationParametersGeneration = organizationData => {
  const options = new URLSearchParams()

  // identity
  options.append('name', organizationData.name)
  options.append('zoho_prefix', organizationData.zoho_prefix)
  options.append('debordement_call_status', organizationData.debordement_call_status ?? '0')
  if (!organizationData.debordement_call_status) {
    options.append('debordement_call_lead_source', '')
  } else {
    options.append('debordement_call_lead_source', organizationData.debordement_call_lead_source ?? '')
  }
  options.append('allow_consumption_historic_on_mes', organizationData.allow_consumption_historic_on_mes ?? '0')
  options.append('allow_cdf_on_mes', organizationData.allow_cdf_on_mes ?? '0')
  options.append('car_range_rule_active', organizationData.car_range_rule_active ?? '0')
  options.append('allow_disclaimer_commitment_pro', organizationData.allow_disclaimer_commitment_pro ?? '0')
  options.append('can_change_meter_option', organizationData.can_change_meter_option ?? '0')
  options.append('can_change_meter_power', organizationData.can_change_meter_power ?? '0')
  options.append('real_payment_car_mandatory', organizationData.real_payment_car_mandatory ?? '0')
  options.append('sale_id_mandatory', organizationData.sale_id_mandatory ?? '0')
  options.append('energy_index_mandatory_non_communicant_meters', organizationData.energy_index_mandatory_non_communicant_meters ?? '0')
  options.append('energy_car_limit_mes_status', organizationData.energy_car_limit_mes_status ?? '0')
  options.append('energy_car_limit_mes_percent', organizationData.energy_car_limit_mes_percent ?? '50')

  if (organizationData.mes_emergency_express) {
    options.append('mes_emergency_express', organizationData.mes_emergency_express)
  }
  if (organizationData.mes_emergency_urgent) {
    options.append('mes_emergency_urgent', organizationData.mes_emergency_urgent)
  }

  options.append('car_electricity_minimum', organizationData.car_electricity_minimum ?? '0')
  options.append('car_gas_main_minimum', organizationData.car_gas_main_minimum ?? '0')
  options.append('car_gas_secondary_minimum', organizationData.car_gas_secondary_minimum ?? '0')

  // finance
  options.append('monthly_amount_service', organizationData.monthly_amount_service ?? '0')
  options.append('bic_mandatory', organizationData.bic_mandatory ?? '0')
  options.append('allow_different_cardholder', organizationData.allow_different_cardholder ?? '0')
  options.append('banned_bic_message', organizationData.banned_bic_message ?? '')
  options.append('banned_bic', organizationData.banned_bic ?? '')
  options.append('iban_banned_countries', organizationData.iban_banned_countries ?? '')
  options.append('saving_accounts_allowed', organizationData.saving_accounts_allowed ?? '1')

  // integration
  options.append('tele_sales', organizationData.tele_sales ?? '0')
  options.append('web_sales', organizationData.web_sales ?? '0')
  options.append('zoho_potential_net_status', organizationData.zoho_potential_net_status ?? '')
  options.append('resending_status', organizationData.resending_status ?? '')
  if (organizationData.apiIntegration) {
    options.append('api_workflow', organizationData.api_workflow ?? '')
  } else {
    options.append('api_workflow', '')
  }

  // promo codes
  options.append('promo_codes', JSON.stringify(organizationData.promo_codes ?? []))
  options.append('promo_description', organizationData.promo_description ?? '')

  return options
}
