import { useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CommonFallback from '@/shared/components/CommonFallback'
import ProviderManagementContext from '../ProviderManagementContext'
import useHttp from '@/shared/hooks/useHttp'
import useBusinessType from '@/shared/hooks/useBusinessType'
import saveTelecomOrganization from './Telecom/saveTelecomOrganization'
import saveEnergyOrganization from './Energy/saveEnergyOrganization'
import saveInsuranceOrganization from './Insurance/saveInsuranceOrganization'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useOrganizationEditForm from './useOrganizationEditForm'
import FieldSkeleton from '@/containers/SubscriptionFormPage/SubscriptionForm/FieldSkeleton'

const Edit = () => {
  const { lang } = useSelector(selectAuthData)
  const { type } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const providerContext = useContext(ProviderManagementContext)
  const providerState = providerContext.providerState
  const providerDispatch = providerContext.providerDispatch
  const { sendRequest, getConfig } = useHttp()
  const { id } = useParams()
  const { LocalizedModule, t } = useOrganizationEditForm()
  const loadProviderData = async providerId => {
    await sendRequest(
      providerState.routes.providerInformation.replace('*', providerId) + '?lang=' + lang,
      getConfig('GET'),
      json => providerDispatch({ type: 'setProvider', payload: json }),
    )
  }
  const saveProvider = () => {
    if (type === 'telecom') {
      saveTelecomOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
    if (type === 'energie') {
      saveEnergyOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
    if (type === 'assurance') {
      saveInsuranceOrganization(
        providerState.selectedProvider,
        providerDispatch,
        providerState.routes.update.replace('*', id) + '?lang=' + lang,
        getConfig('PUT'),
        sendRequest,
      )
    }
  }

  useEffect(() => {
    loadProviderData(id)
  }, [id])

  if (!providerState.selectedProvider.uuid) {
    return <CommonFallback />
  }

  if (!LocalizedModule) {
    return
  }

  return (
    <Suspense fallback={<FieldSkeleton />}>
      <LocalizedModule
        providerDispatch={providerDispatch}
        providerState={providerState}
        saveProvider={saveProvider}
        title={t('organizationManagement:edit') + ' : ' + providerState.selectedProvider.name}
      />
    </Suspense>
  )
}

Edit.propTypes = {
  token: PropTypes.string,
}

export default Edit
