import { StyledBody } from 'baseui/card'
import { ParagraphMedium } from 'baseui/typography'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCarbonSatelliteRadar from '@/shared/icons/IconCarbonSatelliteRadar'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import useHomeStyles from '../HomePage/useHomeStyles'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import SDCard from '@/shared/components/SDComponents/SDCard'
import SDNotification from '@/shared/components/SDComponents/SDNotification'
import { useTranslation } from 'react-i18next'

const ErrorPage = ({ currentCountry, currentType, hasErrorType, hasErrorCountry }) => {
  const { cardBonuses } = useHomeStyles()
  const { t } = useTranslation()

  return (
    <main id='main'>
      {hasErrorType && currentType !== '' && (
        <SDNotification type='error'>{t('common:error_type_access').replace('#TYPE#', currentType)}</SDNotification>
      )}
      {hasErrorCountry && (
        <SDNotification type='error'>
          {t('common:error_country_access').replace('#COUNTRY#', currentCountry)}
        </SDNotification>
      )}
      <div className={cardBonuses}>
        <FlexGrid flexGridColumnCount={2} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
          <FlexGridItem>
            <a href='/energie' id='button-access-energy'>
              <SDCard
                title={
                  <p>
                    <IconCarbonLightning size={24} /> Energie
                  </p>
                }
                content={
                  <StyledBody>
                    <ParagraphMedium>Aller sur la partie Energie</ParagraphMedium>
                  </StyledBody>
                }
              ></SDCard>
            </a>
          </FlexGridItem>
          <FlexGridItem>
            <a href='/telecom' id='button-access-telecom'>
              <SDCard
                title={
                  <p>
                    <IconCarbonSatelliteRadar size={24} /> Telecom
                  </p>
                }
                content={
                  <StyledBody>
                    <ParagraphMedium>Aller sur la partie Telecom</ParagraphMedium>
                  </StyledBody>
                }
              ></SDCard>
            </a>
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid flexGridColumnCount={2} flexGridColumnGap='scale800' flexGridRowGap='scale800'>
          <FlexGridItem>
            <a href='/assurance' id='button-access-insurance'>
              <SDCard
                title={
                  <p>
                    <IconCarbonRule size={24} /> Assurance
                  </p>
                }
                content={
                  <StyledBody>
                    <ParagraphMedium>Aller sur la partie Assurance</ParagraphMedium>
                  </StyledBody>
                }
              ></SDCard>
            </a>
          </FlexGridItem>
        </FlexGrid>
      </div>
    </main>
  )
}

export default ErrorPage
