import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, KIND, SIZE } from 'baseui/button'
import useHttp from '@/shared/hooks/useHttp'
import UserSelector from './UserSelector/UserSelector'
import { ChangeUserContainer } from '../styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import IconCarbonUser from '@/shared/icons/IconCarbonUser'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectCommonFormConfigurationData } from '../../SubscriptionFormConfigurationSelectors'
import { updateCommonConfiguration } from '../../SubscriptionFormConfigurationSlice'

const ChangeUser = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { createdByUserId } = useSelector(selectCommonFormConfigurationData)
  const { sendRequest, getConfig } = useHttp()
  const [ableChangeUser, setAbleChangeUser] = useState(false)
  const [users, setUsers] = useState([])
  const [userIdSelected, setUserIdSelected] = useState(createdByUserId)
  const { isAdmin, country } = useSelector(selectAuthData)
  const { change_user_url: changeUserUrl, get_users_url: getUsersUrl } = useSelector(selectPageConfigurationData)

  if (isAdmin === false) {
    return ''
  }

  const changeUserOwner = () => {
    const saveConfirm = confirm('Are you sure?')
    if (!saveConfirm) {
      return
    }

    const config = getConfig('POST')
    const options = new URLSearchParams()
    options.append('userId', userIdSelected)
    config.body = options
    sendRequest(changeUserUrl + '?lang=' + country, config, (response, status) => {
      if (status !== 200) {
        alert('User changed wrongly. Check console.')
        console.error(response)
        return
      }
      dispatch(updateCommonConfiguration({ createdByUserId: userIdSelected }))
    })
  }

  const importUsers = () => {
    sendRequest(getUsersUrl + '?lang=' + country, getConfig('GET'), (response, status) => {
      if (status !== 200) {
        alert("Users can't be loaded.")
        return
      }
      setUsers(response)
      setAbleChangeUser(!ableChangeUser)
    })
  }

  return (
    <ChangeUserContainer>
      {!ableChangeUser && (
        <Button
          id='change-user-open-button'
          kind={KIND.secondary}
          size={SIZE.compact}
          onClick={event => {
            event.preventDefault()
            importUsers()
          }}
          endEnhancer={() => <IconCarbonUser size={24} />}
        >
          {t('subscriptionForm:subscription_actions_module.change_user.activate_feature')}
        </Button>
      )}
      {ableChangeUser && (
        <>
          <UserSelector users={users} userIdSelected={userIdSelected} setUserIdSelected={setUserIdSelected} />
          <Button
            kind={KIND.primary}
            id='change-user-update'
            size={SIZE.mini}
            overrides={{ BaseButton: { style: { marginLeft: '5px', marginRight: '5px' } } }}
            disabled={userIdSelected === createdByUserId}
            onClick={event => {
              event.preventDefault()
              changeUserOwner()
            }}
          >
            {t('subscriptionForm:subscription_actions_module.change_user.update')}
          </Button>
          <Button
            kind={KIND.primary}
            id='change-user-cancel-button'
            size={SIZE.mini}
            overrides={{ BaseButton: { style: { marginLeft: '5px', marginRight: '5px' } } }}
            onClick={event => {
              event.preventDefault()
              setAbleChangeUser(false)
            }}
          >
            {t('subscriptionForm:subscription_actions_module.change_user.cancel')}
          </Button>
        </>
      )}
    </ChangeUserContainer>
  )
}

export default ChangeUser
