import { Skeleton } from 'baseui/skeleton'

const FieldSkeleton = ({ rows }) => {
  const skeletonOverrides = {
    Row: {
      style: {
        height: '3rem',
        marginBottom: '1rem',
      },
    },
  }
  ;<Skeleton animation rows={rows ?? 2} width='100%' overrides={skeletonOverrides} />
}

export default FieldSkeleton
