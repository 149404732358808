import useBusinessVerticalAccess from './useBusinessVerticalAccess'
import { Card } from '../styled-components'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

const BusinessVerticalAccess = () => {
  const { otherVerticals, t, switchToVertical } = useBusinessVerticalAccess()

  if (otherVerticals.length === 0) return ''

  return (
    <Card>
      {otherVerticals.map((site, index) => (
        <SDButton
          key={index}
          block={false}
          size={SIZE.compact}
          onClick={() => {
            switchToVertical(site.business_type)
          }}
        >
          {t('subscriptionForm:subscription_actions_module.other_vertical.button_' + site.business_type)}
        </SDButton>
      ))}
    </Card>
  )
}

export default BusinessVerticalAccess
