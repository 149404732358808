import { KIND } from 'baseui/button'
import PropTypes from 'prop-types'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { ModalSection, ModalText } from '@/shared/components/SDComponents/SDModal/styled-components'
import { useTranslation } from 'react-i18next'
import useHttp from '@/shared/hooks/useHttp'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerSubscriptionData } from '@/containers/SubscriptionFormPage/SubscriptionFormSelectors'
import { updateCustomerData } from '@/containers/SubscriptionFormPage/SubscriptionFormSlice'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const EkomiModalContent = ({ sendRequest, isLoading }) => {
  const { t } = useTranslation()
  const { getConfig } = useHttp()
  const dispatch = useDispatch()
  const { country } = useSelector(selectAuthData)
  const { zohoId, name, surname, civility, phone1, isEkomiRegistered, email } =
    useSelector(selectCustomerSubscriptionData)
  const { interaction_ekomi_mail_url: interactionEkomiMailUrl, ekomi_status: ekomiStatus } =
    useSelector(selectPageConfigurationData)

  // todo extract into external method
  const sendEkomi = async event => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('firstname', name)
    formData.append('lastname', surname)
    formData.append('civility', civility)
    formData.append('email', email)
    formData.append('zoho_id', zohoId)
    formData.append('phone1', phone1)

    const config = getConfig('POST')
    config.body = formData

    await sendRequest(interactionEkomiMailUrl + '?lang=' + country, config, response => {
      dispatch(updateCustomerData({ isEkomiRegistered: response.success }))
    })
  }

  if (!ekomiStatus) {
    return <></>
  }

  return (
    <ModalSection>
      <ModalText>{t('subscriptionForm:header.modal.ekomi_reminder')}</ModalText>
      <SDButton
        kind={KIND.secondary}
        block={true}
        isLoading={isLoading}
        disabled={isLoading || isEkomiRegistered}
        onClick={sendEkomi}
      >
        {isEkomiRegistered
          ? t('subscriptionForm:header.modal.ekomi_sent')
          : t('subscriptionForm:header.modal.ekomi_mail')}
      </SDButton>
    </ModalSection>
  )
}

EkomiModalContent.propTypes = {
  sendRequest: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default EkomiModalContent
