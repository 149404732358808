import PropTypes from 'prop-types'
import { KIND } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { ModalSection, ModalText } from '@/shared/components/SDComponents/SDModal/styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateBillingData } from '@/containers/SubscriptionFormPage/SubscriptionFormSlice'

const BusinessCardModalContent = ({ businessCard }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <ModalSection>
      <ModalText></ModalText>
      <SDButton
        kind={KIND.secondary}
        block={true}
        onClick={() => dispatch(updateBillingData({ businessCard: true }))}
        disabled={businessCard === true}
      >
        {businessCard === true
          ? t('subscriptionForm:header.modal.business_card_sent')
          : t('subscriptionForm:header.modal.business_card')}
      </SDButton>
    </ModalSection>
  )
}

BusinessCardModalContent.propTypes = {
  businessCard: PropTypes.bool,
}

export default BusinessCardModalContent
