import { useState } from 'react'
import { Button, KIND, SIZE } from 'baseui/button'
import useHttp from '@/shared/hooks/useHttp'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import useModal from '@/shared/hooks/useModal'
import { Show } from 'baseui/icon'
import ModalLogs from './ModalLogs'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'

const HistoricAuditLogsComponent = () => {
  const { t } = useTranslation()
  const { audit_logs_url: auditLogsUrl } = useSelector(selectPageConfigurationData)
  const { isOpen, close, setIsOpen } = useModal()
  const { isAdmin, country } = useSelector(selectAuthData)
  const { sendRequest, getConfig } = useHttp()
  const [logs, setLogs] = useState([])

  const fetchAuditsLogs = () => {
    sendRequest(auditLogsUrl + '?lang=' + country, getConfig('GET'), (response, statusCode) => {
      if (statusCode === 200 && response && Object.keys(response).length > 0) {
        setLogs(response)
      }
      setIsOpen(true)
    })
  }

  if (isAdmin === false) {
    return ''
  }

  return (
    <>
      <Button
        kind={KIND.secondary}
        size={SIZE.compact}
        onClick={event => {
          event.preventDefault()
          fetchAuditsLogs()
        }}
        endEnhancer={() => <Show size={24} />}
      >
        {t('subscriptionForm:subscription_actions_module.historic_logs.view_historic_logs_button')}
      </Button>

      <Modal
        onClose={close}
        isOpen={isOpen}
        size={SIZE.auto}
        overrides={{
          Dialog: {
            style: {
              width: '80vw',
            },
          },
        }}
      >
        <ModalHeader>{t('subscriptionForm:subscription_actions_module.historic_logs.modal_header')}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <ModalLogs data={logs} />
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={KIND.primary} onClick={close}>
            {t('subscriptionForm:subscription_actions_module.historic_logs.modal_close_button')}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default HistoricAuditLogsComponent
