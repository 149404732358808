import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconMySelectraCheck = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'My Selectra check'
  size = size ?? 32
  color = color ?? theme.colors.primary

  return (
    <Icon viewBox='-5 0 30 25' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M7.25731 16.7695L3.33158 10.804C4.4111 9.52236 7.13456 8.48739 8.23874 9.94191C10.1035 12.4804 10.281 12.565 10.6168 12.1086C11.7595 10.2593 15.8766 2.63428 15.9205 2.57141H3.66554C2.37663 2.58471 0.961943 3.92013 1.00106 5.50159L0.998047 17.7362C1.01129 19.0305 2.22378 20.6567 3.70767 20.6567L16.9423 20.65C18.4261 20.6567 19.626 19.3503 19.6043 17.9278L19.626 5.79177C17.1691 10.1656 13.7825 16.674 13.7753 16.6867C12.3113 19.2348 9.02401 19.1853 7.25731 16.7695Z"
            fill={color}>

      </path>
    </Icon>
  )
}

IconMySelectraCheck.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconMySelectraCheck
