import { Suspense } from 'react'
import useOrganizationForm from '@/containers/OrganizationsManagement/OrganizationForm/useOrganizationForm'
import FieldSkeleton from '@/containers/SubscriptionFormPage/SubscriptionForm/FieldSkeleton'

const Add = () => {
  const { LocalizedModule } = useOrganizationForm()
  if (! LocalizedModule) {
    return
  }
  return (
      <Suspense fallback={<FieldSkeleton />}>
        <LocalizedModule />
      </Suspense>
  )
}

export default Add
