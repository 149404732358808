const additionalSalesConfig = {
  assistanceOptions: { fetchStatus: false, resendEmail: false, isInteraction: false },
  carbonOffset: { fetchStatus: false, resendEmail: false, isInteraction: false },
  protected: { fetchStatus: false, resendEmail: false, isInteraction: false },
  axaAssurance: { fetchStatus: false, resendEmail: false, isInteraction: false },
  voltalis: { fetchStatus: false, resendEmail: false, isInteraction: false },
  surveillanceCamera: { fetchStatus: false, resendEmail: false, isInteraction: false },
  assurance: { fetchStatus: false, resendEmail: false, isInteraction: false },
  telecomComparator: { fetchStatus: false, resendEmail: false, isInteraction: false },
  telecomEnergy: { fetchStatus: false, resendEmail: false, isInteraction: false },
  telecomFree: { fetchStatus: false, resendEmail: false, isInteraction: false },
  telecomSat: { fetchStatus: false, resendEmail: false, isInteraction: false },
  assuranceProTelco: { fetchStatus: false, resendEmail: false, isInteraction: false },
  verisureTelecom: { fetchStatus: false, resendEmail: false, isInteraction: false },
  garanka: { fetchStatus: false, resendEmail: false, isInteraction: false },
  engieHomeService: { fetchStatus: false, resendEmail: false, isInteraction: false },
  myChauffage: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadTelecom: { fetchStatus: false, resendEmail: false, isInteraction: false },
  nextories: { fetchStatus: false, resendEmail: false, isInteraction: false },
  muterLoger: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadTransferMuterLoger: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadBank: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadYouStock: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadEnergyPro: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadEnergyProPlaceDesEnergies: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadGasBottle: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadPartnership: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadProxiserve: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadWater: { fetchStatus: false, resendEmail: false, isInteraction: true },
  mesAllocs: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadGroupPurchase: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadAssistancePro: { fetchStatus: false, resendEmail: false, isInteraction: false },
  edfInteraction: { fetchStatus: false, resendEmail: false, isInteraction: true },
  leadEnergyRenovation: { fetchStatus: false, resendEmail: false, isInteraction: false },
  proxiserveRadiator: { fetchStatus: false, resendEmail: false, isInteraction: false },
  proxiserveWaterHeater: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadMonConseillerPerso: { fetchStatus: true, resendEmail: true, isInteraction: false },
  leadAssuranceEmprunteur: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadBankSG: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadIsolation: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadChargingPoint: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadEkwateurSolarKit: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadSolarPanelsOtovoTE: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadComparateur: { fetchStatus: false, resendEmail: false, isInteraction: false },
  LeadBankinAssuranceSale: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadProxiservePAC: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadVerisure: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadTucoenergie: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadOffshoreEnergie: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadOffshoreTelecom: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadAssurancePro: { fetchStatus: false, resendEmail: false, isInteraction: false },
  accessoriesSfr: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadFloraAssurance: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadYagoAssurance: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadSantevetAssurance: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadElite: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadEngieBrussels: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadVerisureTelecomBe: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadBoilerEneco: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadBoilerHomeserve: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadVoltalisBizdev: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadCresus: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadActivationMySelectra: { fetchStatus: false, resendEmail: false, isInteraction: false },
  leadAxaSmartphone: { fetchStatus: false, resendEmail: false, isInteraction: false },
}

export default additionalSalesConfig

export const mapAdditionalKeyNames = key => {
  switch (key) {
    case 'assistanceOptions':
      return 'leadAssistanceOptions'
    case 'carbonOffset':
      return 'leadCarbonOffset'
    case 'surveillanceCamera':
      return 'leadSurveillanceCamera'
    case 'voltalis':
      return 'leadVoltalis'
    case 'voltalisBizdev':
      return 'leadVoltalisBizdev'
    case 'muterLoger':
      return 'leadMuterLoger'
    case 'engieHomeService':
      return 'leadEngieHomeService'
    case 'garanka':
      return 'leadGaranka'
    case 'myChauffage':
      return 'leadMyChauffage'
    case 'nextories':
      return 'leadNextories'
    default:
      return key
  }
}
