import { call, cancelled, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateCharacteristicsModuleConfiguration } from '../../../SubscriptionFormConfigurationSlice'

export const characteristicsModuleActions = {
  SEARCH_ADDRESS: 'SEARCH_ADDRESS',
  CITY_ELIGIBILITY_CHECK: 'CITY_ELIGIBILITY_CHECK',
}

export function* searchAddress({ address, callback }) {
  const controller = new AbortController()
  const fullUrl = `https://api-adresse.data.gouv.fr/search?q=${encodeURIComponent(address)}`

  try {
    const response = yield call(fetch, fullUrl, { signal: controller.signal })
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    const results = responseData.features.map(result => {
      return {
        address: result.properties.street ? result.properties.street : result.properties.name ?? '',
        label: result.properties.label,
        city: result.properties.city,
        inseeCode: result.properties.citycode,
        zipcode: result.properties.postcode,
        houseNumber: result.properties.housenumber,
        type: result.properties.type,
        context: result.properties.context,
      }
    })
    callback(results, isValid)
  } catch (error) {
    console.warn('searchAddressError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in searchAddress aborted')
    }
  }
}

export function* cityEligibilityCheck({ inseeCode }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { eld_check_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&city=${encodeURIComponent(inseeCode)}&energy=dual`
  yield put(updateCharacteristicsModuleConfiguration({ cityEligibilityIsLoading: true }))

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const isValid = response.status === 200
    const responseData = yield call([response, response.json])
    yield put(updateCharacteristicsModuleConfiguration({ cityEligibilityIsLoading: false }))
    if (!isValid) {
      console.warn('cityEligibilityCheckError', responseData, response.status)
      return
    }
    yield put(updateCharacteristicsModuleConfiguration({ cityEligibility: responseData }))
  } catch (error) {
    yield put(updateCharacteristicsModuleConfiguration({ cityEligibilityIsLoading: false, cityEligibility: {} }))
    console.warn('verifyZohoIdError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in cityEligibilityCheck aborted')
    }
  }
}
