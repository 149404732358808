import ValidationStatusBadge from './ValidationStatusBadge'
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover'
import { popoverSharedOverrides } from '../form-header-styled-components'
import ValidationList from './ValidationList'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectCommonFormConfigurationData } from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSelectors'

const FormValidation = () => {
  const { isEditable, canSendSubscription, formCanBeSubmitted, errors, missingFieldsDetails } = useSelector(
    selectCommonFormConfigurationData,
  )
  const { t } = useTranslation()
  const status = (() => {
    if (isEditable) {
      if (Object.keys(errors).length) {
        return {
          status: t('subscriptionForm:header.form_errors'),
          colour: 'negative',
        }
      }

      if (Object.keys(missingFieldsDetails).length > 0) {
        return {
          status: t('subscriptionForm:header.missing_info'),
          colour: 'warning',
        }
      }

      if (canSendSubscription === false) {
        return {
          status: t('subscriptionForm:header.cant_send_subscription'),
          colour: 'warning',
        }
      }

      if (formCanBeSubmitted) {
        return {
          status: t('subscriptionForm:header.ready_submission'),
          colour: 'positive',
        }
      }
    }

    return {
      status: t('subscriptionForm:header.not_editable'),
      colour: 'positive',
    }
  })()
  const popoverContent = () => {
    return canSendSubscription === false ? (
      t('subscriptionForm:header.cant_send_subscription_details')
    ) : (
      <ValidationList errors={errors} missing={missingFieldsDetails} status={status.colour} />
    )
  }

  if (status.colour === 'positive') {
    return <ValidationStatusBadge text={status.status} status={status.colour} />
  }

  return (
    <>
      <StatefulPopover
        overrides={popoverSharedOverrides}
        content={popoverContent()}
        accessibilityType={'tooltip'}
        triggerType={TRIGGER_TYPE.hover}
      >
        <span>
          <ValidationStatusBadge text={status.status} status={status.colour} />
        </span>
      </StatefulPopover>
    </>
  )
}

export default FormValidation
