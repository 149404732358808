import { SIZE } from 'baseui/input'
import PropTypes from 'prop-types'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'

export const HOPButtonGroup = ({ disabledOptions, onClickHandler, options, selectedValue, size, ...rest }) => {
  const selected = []
  const buttons = []

  if (options.length > 0) {
    options.forEach((option, index) => {
      Object.keys(option).forEach(key => {
        const isDisabled = disabledOptions?.includes(key)

        if (Array.isArray(selectedValue)) {
          if (selectedValue.includes(key)) {
            selected.push(index)
          }
        } else {
          if (key === selectedValue) {
            selected.push(index)
          }
        }

        buttons.push(
          <SDButtonGroupButton
            id={'checkbox-option-' + key}
            key={key}
            disabled={isDisabled}
            onClick={event => {
              event.preventDefault()
              onClickHandler(key)
            }}
          >
            {option[key]}
          </SDButtonGroupButton>,
        )
      })
    })
  }

  return (
    <SDButtonGroup size={size ? SIZE[size] : SIZE.compact} selected={selected} {...rest}>
      {buttons}
    </SDButtonGroup>
  )
}

HOPButtonGroup.propTypes = {
  disabledOptions: PropTypes.array,
  onClickHandler: PropTypes.func,
  options: PropTypes.array,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.string,
}
