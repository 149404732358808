import { useEffect, useState, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import useFormStatus from '@/containers/SubscriptionFormPage/useFormStatus'
import { useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useOfferEditForm = () => {
  const { t } = useTranslation()
  const { getApplicationTypeFolder } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
  })
  const { formIsLoaded, LoadingPanel, EmptyPanel, zohoIsInValid, isEditable } = useFormStatus()
  const [LocalizedModule, setLocalizedModule] = useState('')
  const { country } = useSelector(selectAuthData)

  useEffect(() => {
    if (!country) return
    const applicationFolder = getApplicationTypeFolder()
    const countryFolder = country.toUpperCase()
    const Localized = lazy(() => import(`./${applicationFolder}/${countryFolder}/OfferEdit.jsx`))
    setLocalizedModule(Localized)
  }, [country])

  return {
    formIsLoaded,
    LoadingPanel,
    EmptyPanel,
    zohoIsInValid,
    isEditable,
    t,
    LocalizedModule,
  }
}

export default useOfferEditForm
