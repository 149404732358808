import { Suspense } from 'react'

import FieldSkeleton from '@/containers/SubscriptionFormPage/SubscriptionForm/FieldSkeleton'
import useOfferEditForm from '@/containers/OrganizationsManagement/OfferForm/useOfferEditForm'

const OfferEdit = () => {
  const { LocalizedModule } = useOfferEditForm()
  if (!LocalizedModule) {
    return
  }
  return (
    <Suspense fallback={<FieldSkeleton />}>
      <LocalizedModule />
    </Suspense>
  )
}

export default OfferEdit
