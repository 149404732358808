import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { selectAuth } from './AuthSelectors'
import { authActions } from './AuthSagas'

const useLogin = () => {
  const navigate = useNavigate()
  const { isLoading, error } = useSelector(selectAuth)
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    country: 'fr',
    lang: 'fr',
    emailError: '',
  })

  const { email, password, emailError } = formData

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }))
  }

  const updateCountry = value => {
    setFormData(prevFormData => ({ ...prevFormData, country: value }))
  }

  const updateLang = value => {
    setFormData(prevFormData => ({ ...prevFormData, lang: value }))
  }

  const isValidForm = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const isEmailValid = email.match(emailRegex)

    return isEmailValid && !!password
  }

  const login = () => {
    if (isValidForm()) {
      return dispatch({ type: authActions.LOGIN, ...formData, navigate })
    }

    const emailErrorMessage = 'Invalid email or password'
    setFormData(prevFormData => ({ ...prevFormData, [emailError]: emailErrorMessage }))
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: accessToken =>
      dispatch({ type: authActions.LOGIN_WITH_GOOGLE, accessToken, navigate, country: formData.country }),
    onError: errorResponse => console.log(errorResponse),
  })

  return {
    error,
    formData,
    isLoading,
    login,
    loginGoogle,
    handleChange,
    updateCountry,
    updateLang,
  }
}

export default useLogin
