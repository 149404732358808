import { useEffect, useState, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useOrganizationEditForm = () => {
  const { t } = useTranslation()
  const { getApplicationTypeFolder } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutes: {},
    loaderRoutesInsurance: {},
  })
  const [LocalizedModule, setLocalizedModule] = useState('')
  const { country } = useSelector(selectAuthData)

  useEffect(() => {
    if (!country) return
    const applicationFolder = getApplicationTypeFolder()
    const countryFolder = country.toUpperCase()
    const Localized = lazy(() => import(`./${applicationFolder}/${countryFolder}/Edit.jsx`))
    setLocalizedModule(Localized)
  }, [country])

  return {
    t,
    LocalizedModule,
  }
}

export default useOrganizationEditForm
