import { useEffect, useState } from 'react'
import { Tabs, Tab, ORIENTATION } from 'baseui/tabs-motion'
import SubscribersTools from './SubscribersTools'
import CommandsTools from './CommandsTools'
import { useTranslation } from 'react-i18next'
import CacheSearch from './CacheSearch'
import useHttp from '@/shared/hooks/useHttp'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import ImportPodTool from './ImportPodTool'
import VonageDevTool from './VonageDevTool/VonageDevTool'
import BatchPdlUpdaterTool from '@/containers/DevTools/BatchPdlUpdaterTool'

const DevTools = () => {
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance, authTokenSession, lang, vonageDialerStatus } =
    useSelector(selectAuthData)
  const { getApplicationUrl, type } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const { getConfig, sendRequest } = useHttp()
  const token = authTokenSession
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('2')
  const [data, setData] = useState(null)
  const initUrl = getApplicationUrl('dev-tools')
  const initialize = () => {
    const countryUrl = initUrl ? initUrl + '?lang=' + lang : ''
    sendRequest(countryUrl, getConfig('GET'), json => setData(json))
  }

  useEffect(() => {
    initialize()
  }, [])

  if (!data) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey)
      }}
      orientation={ORIENTATION.vertical}
      activateOnFocus
    >
      <Tab title='Subscribers'>
        <SubscribersTools urls={data.urls} token={token} />
      </Tab>
      <Tab title='Commands'>
        <CommandsTools urls={data.urls} token={token} />
      </Tab>
      <Tab title='Cache'>
        <CacheSearch urls={data.urls} token={token} />
      </Tab>

      {type === 'energie' && vonageDialerStatus && (
        <Tab title='Vonage fake call'>
          <VonageDevTool urls={data.urls} />
        </Tab>
      )}

      {type === 'energie' && (
        <Tab title='Import fake PDL'>
          <ImportPodTool pdlUrl={data.urls.import_pdl} pceUrl={data.urls.import_pce} />
        </Tab>
      )}

      {type === 'energie' && (
        <Tab title='PDL/PCE list Updater'>
          <BatchPdlUpdaterTool batchPdlUpdaterUrl={data.urls.batch_pdl_updater} />
        </Tab>
      )}
    </Tabs>
  )
}

export default DevTools
